import React from "react"

import Layout from "../components/layout"
import ProfilePhoto from "../components/profilephoto"
import SEO from "../components/seo"

import "./index.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Tomáš Pastorek" />
    <div id="header-row">
      <div>
        <div className="subtitle">Ahoj, já jsem</div>
        <h1><div className="firstname">Tomáš</div><div className="lastname">Pastorek</div></h1>
        <div className="subtitle">Software developer</div>
      </div>
      <div id="photo">
        <ProfilePhoto />
      </div>
    </div>
  </Layout>
)

export default IndexPage
